
import { SteelspaceModel } from '@/modules/model/types'
import { CsFlex, CsBtn, CsModelHistory } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'HistoryTab',
  components: {
    CsFlex,
    CsModelHistory,
    CsBtn,
  },
  props: {
    value: {
      type: String,
    },
    secondarySelected: {
      type: String,
    },
    selectToCompare: {
      type: Boolean,
      default: false,
    },
    compareVersionIsActive: { type: Boolean, default: false },
    compareVersionsButtonText: { type: String, default: 'Compare version' },
    errorMessage: { type: String, default: '' },
    errorTitle: { type: String, default: '' },
    errorIcon: { type: String },
    errorIconColor: { type: String },
    loading: { type: Boolean, default: false },
    items: {
      default: [],
      type: Array as () => unknown,
    },
    model: { type: Object as () => SteelspaceModel },
    selectable: { type: Boolean, default: false },
    hideOpen: { type: Boolean, default: false },
    hideRestore: { type: Boolean, default: false },
    hideEdit: { type: Boolean, default: false },
    hideCreate: { type: Boolean, default: false },
    hideDelete: { type: Boolean, default: false },
  },
})
