
import { authStore } from '@/modules/auth'
import { CsFlex, CsCommentTree, CsEmptyComment, CsWarningDialog } from '@consteel/csuetify'
import {
  Comment,
  commentStore,
  CommentTreeNode,
  createResponse,
  deleteComment,
  deleteUnread,
  deleteResponse,
  fetchResponses,
  getCommentLoading,
  getCommentsBasedOnModelCompareState,
  sortCommentsByDate,
  updateComment,
  updateResponse,
} from '@/modules/comment'
import Vue from 'vue'
import {
  CommentSelectType,
  getCompareStateBasedOnFadingLevel,
  ModelStateNumber,
  modelViewerStore,
  moveCameraToComment,
  setDipslaySettings,
  setFloatingComments,
  setHiddenParamOnGuiObjects,
} from '@/store/modelViewer.store'
import { SteelspaceModel } from '@/modules/model'
import CommentTypeSelect from './Comments/CommentTypeSelect.vue'
import { historyStore, parseHistoryItems, setHistoryItems } from '../../modules/history'
import { User } from 'firebase/auth'

export default Vue.extend({
  name: 'CommentTab',
  components: {
    CsFlex,
    CsCommentTree,
    CsEmptyComment,
    CommentTypeSelect,
    CsWarningDialog,
  },
  data() {
    return {
      selectedCommentId: '',
      showDeleteCommentDialog: false,
      deleteCommentItem: {
        commentId: null as string | null,
        itemId: null as string | null,
      },
    }
  },
  props: {
    model: { type: Object as () => SteelspaceModel },
    numberOfUnreadComments: { type: Number, default: 0 },
  },
  computed: {
    isCompare(): boolean {
      return !!commentStore.commentsSecondary
    },
    modelNumber(): string {
      if (this.isCompare) {
        return getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
          ? modelViewerStore.model.modelNumber || ''
          : modelViewerStore.secondaryModel?.modelNumber || ''
      } else {
        const historyId = this.$route.params.historyId

        return (
          parseHistoryItems(historyStore.historyItems).find(
            (historyItem) => historyItem.id === historyId
          )?.number || ''
        )
      }
    },
    comments(): Comment[] {
      return commentStore.comments
    },
    commentsSecondary(): Comment[] | null {
      return commentStore.commentsSecondary
    },
    commentsTree(): CommentTreeNode[] {
      return this.buildCommentTree()
    },
    getCurrentUser(): null | User {
      return authStore.currentSteelspaceUser
    },
    commentLoading(): boolean {
      return getCommentLoading()
    },
    isObjectSpecificComments(): boolean {
      return modelViewerStore.commentSelectType !== CommentSelectType.GLOBAL
    },
  },
  watch: {
    comments() {
      setFloatingComments()
    },
    commentsSecondary() {
      setFloatingComments(ModelStateNumber.secondary)
    },
  },
  methods: {
    isCommentUnread(commentId: string): boolean {
      const relevantUnread = commentStore.unreads.find((unread) => unread.commentId === commentId)
      return !!relevantUnread
    },
    buildCommentTree(): CommentTreeNode[] {
      const commentBasedOnCompareState =
        getCommentsBasedOnModelCompareState(getCompareStateBasedOnFadingLevel()) || []

      const relevantComments = commentBasedOnCompareState.filter((comment) => {
        const isCommentSelected = modelViewerStore.selectedCommentIds.includes(comment.id)

        switch (modelViewerStore.commentSelectType) {
          case CommentSelectType.GLOBAL:
            if (comment.smadsteelIds.length === 0) return true
            break
          case CommentSelectType.OBJECT_SPECIFIC:
            if (comment.smadsteelIds.length === 1 && isCommentSelected) return true
            break
          case CommentSelectType.OBJECT_GROUP_SPECIFIC:
            if (comment.smadsteelIds.length > 1 && isCommentSelected) return true
            break
          default:
            return false
        }
        return false
      })

      const commentTrees: CommentTreeNode[] = []
      for (const comment of relevantComments) {
        const commentResponses = commentStore.responses[comment.id]
        const responses: CommentTreeNode[] = []
        if (commentResponses) {
          for (const rawResponse of commentResponses) {
            const response: CommentTreeNode = {
              id: rawResponse.id,
              email: rawResponse.userData.email,
              userName: rawResponse.userData.name,
              role: '',
              timestampInMS: rawResponse.creationDate,
              comment: rawResponse.text,
            }
            responses.push(response)
          }
          sortCommentsByDate(responses)
        }
        const rootComment: CommentTreeNode = {
          id: comment.id,
          email: comment.userData.email,
          userName: comment.userData.name,
          role: '',
          timestampInMS: comment.creationDate,
          replyCnt: comment.responseCount,
          comment: comment.text,
          children: responses,
        }
        commentTrees.push(rootComment)
      }
      sortCommentsByDate(commentTrees)

      return commentTrees
    },
    handleRootCommentClick(rootCommentClicked: boolean, itemId: string): void {
      if (rootCommentClicked) {
        moveCameraToComment(itemId)
        setDipslaySettings(itemId)
        setHiddenParamOnGuiObjects()
        this.selectedCommentId = itemId
      } else {
        this.selectedCommentId = ''
      }
    },
    async handleCommentSaveBtnClick(
      commentId: string,
      commentText: string,
      itemId: string,
      closeEditStateCallback: () => void,
      stopSaveBtnLoadingCallback: () => void
    ): Promise<void> {
      if (commentId === itemId) {
        await updateComment(this.model.id, commentId, commentText, this.$route.params.historyId)
      } else {
        await updateResponse(this.model.id, commentId, itemId, commentText)
      }
      closeEditStateCallback()
      stopSaveBtnLoadingCallback()
    },
    handleCommentDeleteActionBtnClicked(commentId: string, itemId: string): void {
      this.deleteCommentItem = {
        commentId: commentId,
        itemId: itemId,
      }
      this.showDeleteCommentDialog = true
    },
    async handleCommentDeleteBtnClicked(): Promise<void> {
      this.showDeleteCommentDialog = false

      if (!this.deleteCommentItem.commentId || !this.deleteCommentItem.itemId) return

      if (this.deleteCommentItem.commentId === this.deleteCommentItem.itemId) {
        await deleteComment(
          this.model.id,
          this.deleteCommentItem.commentId,
          this.$route.params.historyId
        )
      } else {
        await deleteResponse(
          this.model.id,
          this.deleteCommentItem.commentId,
          this.deleteCommentItem.itemId
        )
      }

      this.deleteCommentItem = {
        commentId: null,
        itemId: null,
      }
    },
    async handleCommentReplySendBtnClick(
      commentId: string,
      responseText: string,
      stopReplyBtnLoadingCallback: () => void
    ): Promise<void> {
      await createResponse(this.model.id, commentId, responseText)
      stopReplyBtnLoadingCallback() // stops reply btn loading
    },
    isCommentAlreadyFetched(commentId: string): boolean {
      return !!commentStore.responses[commentId]
    },
    isUserStoredInUnreads(commentId: string): boolean {
      return this.isCommentUnread(commentId)
    },
    async handleCommentTreeSelectorClick(
      commentId: string,
      treeSelectorState: boolean
    ): Promise<void> {
      if (treeSelectorState) {
        if (!this.isCommentAlreadyFetched(commentId)) {
          await fetchResponses(this.model.id, commentId)
        }
        if (this.isUserStoredInUnreads(commentId)) {
          await deleteUnread(this.model.id, commentId, this.$route.params.historyId)
          const isAnyCommentUnread = commentStore.comments.some((comment) =>
            this.isCommentUnread(comment.id)
          )
          if (!isAnyCommentUnread) {
            setHistoryItems(
              historyStore.historyItems.map((historyItem) => {
                if (historyItem.id === this.$route.params.historyId) {
                  return { ...historyItem, isUnread: false }
                }
                return historyItem
              })
            )
          }
        }
      }
    },
  },
})
