var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "history-tab",
      attrs: { "fill-height": "", "fill-width": "", vertical: "" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row pa-7 pb-5" },
        [
          _c("div", { staticClass: "text-h6" }, [
            _vm._v(_vm._s(_vm.$t("Versions"))),
          ]),
          _c(
            "cs-btn",
            {
              staticClass: "white ml-auto",
              attrs: {
                outlined: "",
                elevation: "1",
                color: "black",
                small: "",
                disabled: this.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("clickCompareVersions")
                },
              },
            },
            [
              _c("v-icon", [_vm._v("mdi-swap-horizontal")]),
              _vm._v(" " + _vm._s(_vm.compareVersionsButtonText)),
            ],
            1
          ),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.compareVersionIsActive
          ? _c("div", { staticClass: "px-7 mb-2 text-right info-label" }, [
              _c(
                "div",
                {
                  staticClass: "grey pa-1 text-center",
                  attrs: { label: "", color: "grey" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "Select another version you want to compare with."
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _c("v-divider"),
      _c("cs-model-history", {
        attrs: {
          items: _vm.items,
          value: _vm.value,
          model: _vm.model,
          loading: this.loading,
          errorMessage: this.errorMessage,
          errorTitle: this.errorTitle,
          errorIcon: this.errorIcon,
          errorIconColor: this.errorIconColor,
          hideEdit: _vm.hideEdit,
          hideOpen: _vm.hideOpen,
          hideCreate: _vm.hideCreate,
          hideDelete: _vm.hideDelete,
          hideRestore: _vm.hideRestore,
          selectable: _vm.selectable,
          selectToCompare: _vm.selectToCompare,
          secondarySelected: _vm.secondarySelected,
        },
        on: {
          input: (event) => _vm.$emit("input", event),
          download: (event) => _vm.$emit("download", event),
          restore: (event) => _vm.$emit("restore", event),
          create: (event) => _vm.$emit("create", event),
          edit: (event) => _vm.$emit("edit", event),
          delete: (event) => _vm.$emit("delete", event),
          errorCancel: (event) => _vm.$emit("errorCancel", event),
          selectSecondary: (event) => _vm.$emit("selectSecondary", event),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }