
import BigArrowSvg from '@/assets/viewer/BigArrow.svg.vue'
import LetterSvg from '@/assets/viewer/Letter.svg.vue'
import SmallArrowSvg from '@/assets/viewer/SmallArrow.svg.vue'
import TriangleSvg from '@/assets/viewer/Triangle.svg.vue'
import ChatSvg from '@/assets/viewer/Chat.svg.vue'
import {
  modelViewerStore,
  scaleComments,
  scaleLabels,
  scaleLoads,
  scaleSupports,
  toggleLoads,
  toggleSupports,
} from '@/store/modelViewer.store'
import { CsFlex, CsSlider } from '@consteel/csuetify'
import Vue from 'vue'
import ClipPlane from './ClipPlane.vue'

export default Vue.extend({
  name: 'DisplayTab',
  components: {
    ClipPlane,
    CsFlex,
    CsSlider,
    SmallArrowSvg,
    BigArrowSvg,
    TriangleSvg,
    LetterSvg,
    ChatSvg,
  },
  data() {
    return {
      loadSlider: 500,
      supportSlider: 250,
      labelSlider: 10,
      commentSlider: 10,
      disabledSupportsLastValue: 0,
      disabledLoadsLastValue: 0,
    }
  },
  computed: {
    unselectedMembersToTransparentOptionDisabled(): boolean {
      return modelViewerStore.unselectedMembersToTransparentOptionDisabled
    },
  },
  methods: {
    displaySupports(display: boolean): void {
      if (!display) {
        this.disabledSupportsLastValue = modelViewerStore.scaleOfSupports
        scaleSupports(0)
      } else {
        scaleSupports(this.disabledSupportsLastValue)
      }
      toggleSupports(display)
    },
    displayLoads(display: boolean): void {
      if (!display) {
        this.disabledLoadsLastValue = modelViewerStore.scaleOfLoads
        scaleLoads(0)
      } else {
        scaleLoads(this.disabledLoadsLastValue)
      }
      toggleLoads(display)
    },
  },
  watch: {
    loadSlider(newVal): void {
      scaleLoads(newVal / 10)
    },
    labelSlider(newVal): void {
      scaleLabels(newVal / 10)
    },
    supportSlider(newVal): void {
      scaleSupports(newVal / 10)
    },
    commentSlider(newVal): void {
      scaleComments(newVal / 10)
    },
  },
})
