
import Vue from 'vue'
import { modelViewerStore, setHiddenParamOnGuiObjects } from '../../store/modelViewer.store'
import { CsSlider } from '@consteel/csuetify'

export default Vue.extend({
  name: 'ClipPlane',
  components: {
    CsSlider,
  },
  data() {
    return {
      clipSliderX: 0,
      clipSliderY: 0,
      clipSliderZ: 0,
      clipPlane: false,
    }
  },
  computed: {
    clipSliderMax(): number {
      return 100
    },
    clipSliderMin(): number {
      return 0
    },
  },
  methods: {
    handleToggleClipPlane(event: any): void {
      const value = event.target.checked
      this.clipPlane = value
      if (!modelViewerStore.currentView) return

      if (!value) {
        modelViewerStore.currentView.ClearClipPlaneX()
        modelViewerStore.currentView.ClearClipPlaneY()
        modelViewerStore.currentView.ClearClipPlaneZ()
      } else {
        modelViewerStore.currentView.SetClipPlaneX(this.clipSliderX)
        modelViewerStore.currentView.SetClipPlaneY(this.clipSliderY)
        modelViewerStore.currentView.SetClipPlaneZ(this.clipSliderZ)
      }
      setHiddenParamOnGuiObjects()
    },
  },
  watch: {
    clipSliderX(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneX(value)
      setHiddenParamOnGuiObjects()
    },
    clipSliderY(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneY(value)
      setHiddenParamOnGuiObjects()
    },
    clipSliderZ(value: number): void {
      modelViewerStore.currentView?.SetClipPlaneZ(value)
      setHiddenParamOnGuiObjects()
    },
  },
})
